@import '../../../defaults';

.anw-danger-border-bar {
  background: $white;
  border: 2px solid $red;
  color: $red;

  a,
  a:hover {
    background: transparent;
    color: $red;
    text-decoration: none;
  }

  button {
    font-weight: $font-weight-bold;
  }
}
